<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4" lg="3" xl="2">
						<v-card class="elevation-12" loading>
							<v-card-text class="text-h4 text-center">
								{{ gTranslate('Wait please...') }}
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
/**********************************************************************************************
**** WAIT GLOBALE , usato ad esempio durante il cambio azienda ********************************
***********************************************************************************************
* 
*/

export default {
	name: 'WaitPage',
	components: {  },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
		if(this.$store.getters['keyvalues']){ 
			if(this.$store.getters['keyvalues']['theme.dark']) {
				var val1 = this.$store.getters['keyvalues']['theme.dark'] === 'true'
				this.$vuetify.theme.dark = val1 
			}
		}
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>